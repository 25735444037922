import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { prepareForEditor } from 'libs/html-editor/helpers/quill.helper';
import Quill, { Delta } from 'quill';

@Pipe({
  name: 'prepareStoryHtmlText',
  pure: true,
})
export class PrepareStoryHtmlTextPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    const ops = JSON.parse(value);

    const preparedForEditor = prepareForEditor({ ops } as Delta);

    var tempCont = document.createElement('div');
    new Quill(tempCont).setContents(preparedForEditor);
    const html = tempCont.getElementsByClassName('ql-editor')[0].innerHTML;

    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
