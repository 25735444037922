import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MentionModule } from 'angular-mentions';
import { CarouselModule } from 'primeng/carousel';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { HighlightTextareaComponent } from './components/highlight-textarea/highlight-textarea.component';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { FileDragAndDropDirective } from './directives/file-drag-and-drop.directive';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { PrepareMentionTextPipe } from './pipes/prepare-mention-text.pipe';
import { PrepareStoryHtmlTextPipe } from './pipes/prepare-story-v2-text.pipe';
import { TextToUrlPipe } from './pipes/text-to-url.pipe';

@NgModule({
  declarations: [
    UploadFilesComponent,
    FileDragAndDropDirective,
    HighlightTextareaComponent,
    PrepareMentionTextPipe,
    LocalizedDatePipe,
    TextToUrlPipe,
    PrepareStoryHtmlTextPipe,
  ],
  imports: [
    CommonModule,
    FileUploadModule,
    CarouselModule,
    FormsModule,
    MentionModule,
    InputTextareaModule,
    TranslateModule,
    ProgressSpinnerModule,
  ],
  exports: [
    UploadFilesComponent,
    HighlightTextareaComponent,
    PrepareMentionTextPipe,
    LocalizedDatePipe,
    TextToUrlPipe,
    PrepareStoryHtmlTextPipe,
  ],
})
export class SharedModule {}
