import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'libs/services/auth';
import { LocalStorageClass } from 'libs/shared/functions/local-storage.class';
import { AuthState } from 'libs/states/auth';
import * as authSelectors from 'libs/states/auth/+state/auth.selectors';
import * as uiActions from 'libs/states/ui-state/+state/ui.actions';
import { UiState } from 'libs/states/ui-state/+state/ui.reducer';
import { PrimeNGConfig } from 'primeng/api';
import Quill from 'quill';
import { Mention, MentionBlot } from 'quill-mention';
import { BehaviorSubject, distinctUntilChanged, filter, take, tap } from 'rxjs';

Quill.register({ 'blots/mention': MentionBlot, 'modules/mention': Mention });
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Jenz-Web';
  token = LocalStorageClass.getToken();
  selectedLanguage = localStorage.getItem('selected-language');

  termsAndConditionsUrl = 'https://jenz.app/privacy-policy/?preview=true';

  termsAndConditionsAcceptedOnInit =
    LocalStorageClass.getTermsAndConditionsAccepted() ?? false;

  termsAndConditionsAccepted = false;

  isMobileSubject = new BehaviorSubject<boolean>(false);
  isMobile$ = this.isMobileSubject.asObservable().pipe(
    distinctUntilChanged(),
    tap((isMobile) =>
      this.uiState.dispatch(uiActions.updateIsMobile({ isMobile }))
    )
  );

  pageReady = new BehaviorSubject<boolean>(false);
  pageReady$ = this.pageReady.asObservable();

  currentUser$ = this.authState.select(authSelectors.getCurrentUser).pipe(
    filter((user) => !!user),
    tap(() => {
      this.activateInitialSpinner();
      this.authService.getGlobalApplicationConfiguration().subscribe();
    }),
    take(1)
  );

  constructor(
    private authService: AuthService,
    private authState: Store<AuthState>,
    private primeNgConfig: PrimeNGConfig,
    private translate: TranslateService,
    private uiState: Store<UiState>,
    private router: Router
  ) {
    this.configureTranslateService();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.detectIsMobile();
  }

  // Used to track if users has clicked on the mention
  @HostListener('click', ['$event'])
  onClick(e) {
    if (e.target.localName !== 'mark' && e.target.nodeName !== 'MARK') return;

    const className = e.target?.className as string;

    if (!className?.includes('clickable')) return;

    const userIdStart = className.indexOf('=') + 1;

    const userId = className.slice(userIdStart, className?.length);

    if (isNaN(Number(userId))) return;

    this.router.navigate(['people', { userId }]);
  }

  ngOnInit() {
    if (this.token) {
      this.activateInitialSpinner();
    } else this.pageReady.next(true);

    this.detectIsMobile();

    this.isMobile$.subscribe();
    this.currentUser$.subscribe();
  }

  activateInitialSpinner() {
    this.pageReady.next(false);

    setTimeout(() => {
      this.pageReady.next(true);
    }, 2000);
  }

  configureTranslateService() {
    this.translate.addLangs(['en', 'hr', 'de', 'it', 'sl', 'pt']);
    this.translate.setDefaultLang('en');
    this.translate.use(LocalStorageClass.getSelectedLanguage());

    this.translate
      .get('primeNg')
      .subscribe((res) => this.primeNgConfig.setTranslation(res));
  }

  detectIsMobile(): void {
    this.isMobileSubject.next(window.innerWidth < 770);
  }

  currentLanguage = '';
  selectLanguage(language) {
    this.currentLanguage = language?.value;
    this.termsAndConditionsUrl = language?.termsAndConditionsUrl;
  }

  languageSelectedAndTermsAndConditionsAccepted() {
    if (!this.currentLanguage?.length || !this.termsAndConditionsAccepted)
      return;

    this.selectedLanguage = this.currentLanguage;
    this.translate.use(this.currentLanguage);

    LocalStorageClass.setSelectedLanguage(this.selectedLanguage);
    LocalStorageClass.acceptTermsAndConditions();

    this.termsAndConditionsAcceptedOnInit = true;
  }
}
